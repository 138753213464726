<template>
  <div
    class="print_tag"
    :class="{
      is_one_col: setting.cols === 1,
    }"
  >
    <div v-html="tagListDomString"></div>
    <!-- <div>
      <div
        v-for="(item, index) in list"
        :key="index"
        class="print_page_end_mark"
        :style="{
          width: `${width_space.total_width}mm`,
        }"
      >
        <img
          v-for="(img, index1) in item"
          :key="index1"
          :src="img"
          alt=""
          :style="{
            width: `${width_space.width}mm`,
            height: `${width_space.height - 0.5}mm`,
            'margin-right': `${
              index1 < item.length - 1 ? width_space.space : 0
            }mm`,
          }"
        />
      </div>
    </div> -->
  </div>
</template>

<script>
import { delay } from '@/util/commonFuncs'
import { getDeviceXDPI, mmToPx } from '@/util/exportFuncs'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      urls: [],
      setting: { cols: 1, space: 5 },
      list: [],
      needTranslate: false,
      needScale: true,
      scale: 1,
      dpi: getDeviceXDPI(),
      tagListDomString: '',
    }
  },
  methods: {
    goBack() {
      setTimeout(() => {
        this.$router.push({
          path: '/printTag',
        })
      }, 200)
    },
    translateFn(val) {
      let result
      if (this.needTranslate) {
        result = mmToPx(val, this.dpi)
      } else {
        result = val
      }
      if (this.needScale) {
        return result * this.scale
      }
    },
    // 生成按组分配的dom字符串
    genGroupDomString(arr) {
      return `<div class="print_page_end_mark" style="width: ${
        this.rowTotalWidth
      }mm">${arr.join('')}}}</div>`
    },
  },
  async mounted() {
    // 取消或者关闭后，返回上一个页面
    window.onafterprint = this.goBack

    let len = this.$store.state.tagValue.length
    if (len) {
      this.urls = this.$store.state.tagValue
    }
    // 这儿对每个标签进行缩放
    const tagScale = this.$store.state.tagScale
    this.tagListDomString = this.$store.state.tagListDomString

    await delay(20)
    let tagListDom = document.querySelectorAll('.print_tag .print_mark')
    let arr = []
    let newString = ''
    this.setting = this.$store.state.tagPrintSetting
    let cols = this.setting.cols || 1
    // 将标签按照cols进行分组
    if (cols > 1) {
      tagListDom.forEach((dom) => {
        arr.push(dom.outerHTML)
        if (arr.length === cols) {
          newString += this.genGroupDomString(arr)
          arr = []
        }
      })
      // 将剩余的dom单独放到一组
      if (arr.length) {
        newString += this.genGroupDomString(arr)
      }
      this.tagListDomString = newString
    }
    await delay(20)
    // 因为dom重新渲染了，所以重新获取dom
    tagListDom = document.querySelectorAll('.print_tag .print_mark')
    tagListDom.forEach((item, index) => {
      item.style.width = this.$store.state.curTemplate.tag_width + 'mm'
      // 减去0.3是因为相同大小会超出打印预览区域
      item.style.height = this.$store.state.curTemplate.tag_height - 0.3 + 'mm'
      // 如果是多列，就设置margin-right
      if (cols > 1 && (index + 1) % this.setting.cols !== 0) {
        item.style.marginRight = this.setting.col_space + 'mm'
      }
      // 设置 render_svg_item 的宽高(0.005是为了在上面减去0.3的情况下让标签显示的更完整)
      item.children[0].style.transform = `scale(${tagScale - 0.005})`
    })

    await delay(20)
    window.print()

    if (len) {
      // this.urls = this.$store.state.tagValue
      // this.setting = this.$store.state.tagPrintSetting
      // let cols = this.setting.cols || 1

      // let arr = []
      // this.collect.forEach((item) => {
      //   arr.push(item)
      //   if (arr.length === cols) {
      //     this.list.push(arr)
      //     arr = []
      //   }
      // })
      // if (arr.length) {
      //   this.list.push(arr)
      // }

      setTimeout(() => {
        // window.print()
      }, 100)
    } else {
      this.goBack()
    }
  },
  computed: {
    ...mapState(['collect']),
    // calWidth() {
    //   return (value) => {
    //     return Math.floor(+value) - 1
    //   }
    // },
    // 每一行的总宽度
    rowTotalWidth() {
      return (
        this.setting.cols * this.$store.state.curTemplate.tag_width +
        (this.setting.cols - 1) * this.setting.col_space
      )
    },
  },
}
</script>

<style lang="less">
/* .print_tag {
  margin: 0 auto;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.print_tag > div {
  margin: 0 auto;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}
*/

.print_tag {
  font-size: 0 !important;
  display: none;
  &.is_one_col {
    .print_mark {
      display: block;
    }
  }
  .print_mark {
    // position: relative;
    display: inline-block;
    overflow: hidden;
    box-sizing: border-box;
    // width: 60mm;
    // height: 40mm;
    /* margin: 0 0 20px; */
  }
  .render_svg_item {
    // 包裹svg
    display: inline-block;
  }
  .render_svg_item,
  .render_svg_item svg {
    transform-origin: left top;
  }
}

@media print {
  .print_tag {
    display: block !important;
    &.is_one_col {
      .print_mark {
        display: block !important;
        page-break-after: always;
        margin: 0 !important;
        padding: 0 !important;
      }
      .print_mark:last-child {
        page-break-after: auto !important;
      }
    }
  }
  .print_page_end_mark {
    display: block !important;

    page-break-after: always;
    margin: 0 !important;
    padding: 0 !important;
  }
  .print_page_end_mark img {
    box-sizing: border-box !important;
    /* border: solid 1px red; */
  }
  .print_page_end_mark:last-child {
    page-break-after: auto !important;
  }
}
@page {
  margin: 0mm 0mm 0mm 0mm !important;
  /* size: auto;
  margin: 0; */
}
</style>
